import React from 'react';
import { IconSVGProps } from '../Icon';

const IconProducts = ({ small }: IconSVGProps) => {
  return (
    <svg
      width={small ? '24' : '32'}
      height={small ? '24' : '32'}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeWidth={small ? '0.5' : '0'}
        d="M24,20.5c-0.01,0.28-0.11,0.55-0.29,0.76c-0.69,0.77-1.67,1.22-2.71,1.24c-1.05,0-2.05-0.46-2.73-1.26  C18.1,21.03,18,20.77,18,20.5V7.07c3.44,0.5,6,3.45,6,6.93V20.5z M12,25c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S12,23.9,12,25z   M17.39,5c-1.34-1.22-3.08-1.93-4.89-2C8.89,3,5,5.68,5,10c0,3.29,1.72,5.1,3.24,6.69c0.26,0.27,0.51,0.54,0.75,0.81  c0.98,1.09,1.76,2.36,2.29,3.73C10.87,21.08,10.44,21,10,21c-2.21,0-4,1.79-4,4s1.79,4,4,4s4-1.79,4-4  c-0.06-3.27-1.31-6.41-3.51-8.83c-0.26-0.29-0.53-0.57-0.8-0.86C8.31,13.86,7,12.49,7,10c0-3.09,2.85-5,5.5-5  c1.3,0.07,2.53,0.58,3.5,1.45V20.5c0,0.78,0.29,1.53,0.81,2.11c1.07,1.19,2.59,1.88,4.19,1.89c1.61-0.03,3.14-0.72,4.22-1.92  C25.71,22,25.99,21.26,26,20.5V14C26,9.18,22.21,5.21,17.39,5z"
      />
    </svg>
  );
};

export default IconProducts;
