import React from 'react';
import { IconSVGProps } from '../Icon';

const IconClose = ({ small }: IconSVGProps) => {
  return (
    <svg
      width={small ? '24' : '32'}
      height={small ? '24' : '32'}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeWidth={small ? '0.5' : '0'}
        d="M17.42,16l9.3-9.29c0.39-0.39,0.39-1.03,0-1.42c-0.39-0.39-1.03-0.39-1.42,0l-9.29,9.3l-9.29-9.3  C6.32,4.9,5.69,4.9,5.3,5.29C4.9,5.69,4.9,6.32,5.3,6.71L14.6,16l-9.3,9.29C5.11,25.48,5,25.74,5,26s0.11,0.52,0.3,0.71  c0.19,0.19,0.44,0.3,0.71,0.3c0.27,0,0.52-0.11,0.71-0.3l9.29-9.3l9.29,9.3c0.19,0.19,0.44,0.3,0.71,0.3s0.52-0.11,0.71-0.3  c0.19-0.19,0.3-0.44,0.3-0.71s-0.11-0.52-0.3-0.71L17.42,16z"
      />
    </svg>
  );
};

export default IconClose;
