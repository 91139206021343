import { FALLBACK_LANGUAGE_CODE, getLanguage } from './utils/language';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as resources from './translation';

//get defined language
const langCode = getLanguage().code;
//intially set html lang-tag to current language
document.documentElement.lang = langCode;

//init i18n translations
i18n.use(initReactI18next).init({
  resources,
  lng: langCode,
  keySeparator: ':',
  fallbackLng: FALLBACK_LANGUAGE_CODE,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
