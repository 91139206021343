import IconPlay from './icon-play';
import IconStop from './icon-stop';
import IconPause from './icon-pause';
import IconClose from './icon-close';
import IconProducts from './icon-products';
import IconHeadphone from './icon-headphone';
import IconTestimonials from './icon-testimonials';
import IconArrowLeft from './icon-arrow-left';
import IconGlobe from './icon-globe';

export const icons = {
  IconPlay,
  IconStop,
  IconPause,
  IconClose,
  IconProducts,
  IconHeadphone,
  IconTestimonials,
  IconArrowLeft,
  IconGlobe,
};
