import { FC, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SoundWaveform } from '../../molecules/Waveform/SoundWaveform';
import { Toggle } from '../../cells/Toggle/Toggle';
import { getNonWidexSound, getWidexSound } from '../../../utils/get-sounds';
import { SoundButtonType } from '../../../utils/types';

type Props = {
  selectedSound: SoundButtonType;
  isAudioWidex: (type: boolean) => void;
};

export const SoundDemoPlayer: FC<Props> = ({ selectedSound, isAudioWidex }) => {
  const { t } = useTranslation();
  const widexAudio = getWidexSound(selectedSound);
  const competitorAudio = getNonWidexSound(selectedSound);
  const [sound, setSound] = useState(selectedSound);
  const [audioFile, setAudioFile] = useState<string>(widexAudio);
  const [toggledTime, setToggledTime] = useState(0);
  const [scenarioChange, setScenarioChange] = useState<boolean>(true);

  const toggles = useMemo(
    () => ({
      toggleA: {
        name: t('widexSound'),
        value: 'widex',
      },
      toggleB: {
        name: t('competitor'),
        value: 'competitor',
      },
    }),
    [t],
  );

  // Handles reset of sound to widex sound if sound type changes
  useEffect(() => {
    if (selectedSound) {
      setAudioFile(widexAudio);
    }
  }, [selectedSound, widexAudio]);

  // Handles reset of time if sound type changes
  useEffect(() => {
    if (selectedSound !== sound) {
      setToggledTime(0);
      setSound(selectedSound);
      setScenarioChange(true);
    }
  }, [selectedSound, sound]);

  return (
    <div className="flex flex-col items-center justify-between w-full h-full xs:w-3/4">
      <div className="flex flex-col items-center w-11/12">
        <Toggle
          toggles={toggles}
          audioFile={audioFile.includes('widex') ? 'widex' : 'competitor'}
          setSelected={(type) => {
            setAudioFile(type === 'widex' ? widexAudio : competitorAudio);
            isAudioWidex(type === 'widex');
          }}
        />
      </div>

      <SoundWaveform
        audio={audioFile}
        toggledTime={toggledTime}
        handleAudioProgress={(time) => {
          setToggledTime(time);
        }}
        scenarioChange={scenarioChange}
        setScenarioChange={setScenarioChange}
      />
    </div>
  );
};
