import { useTranslation } from 'react-i18next';
import { Product, ProductType } from './types';

export const useProducts = (): { [key in ProductType]: Product } => {
  const { t } = useTranslation();

  return {
    [ProductType.CIC]: {
      imageSrc: 'assets/images/MOMENT_CIC_double_Leight-Beige_1000x1000.png',
      modelName: t('CIC:modelName'),
      title: t('CIC:title'),
      description: t('CIC:description'),
      suitableFor: t('CIC:suitableFor'),
      src: 'CIC_c/Export-web_CIC_',
      limit: 105,
      slideLimit: 65,
    },
    [ProductType.ITE]: {
      imageSrc: 'assets/images/MOMENT_XP_double_Dark-Brown_1000x1000.png',
      modelName: t('ITE:modelName'),
      title: t('ITE:title'),
      description: t('ITE:description'),
      suitableFor: t('ITE:suitableFor'),
      src: 'ITE_c/Export-web_XP_',
      limit: 105,
      slideLimit: 70,
    },
    [ProductType.RIC]: {
      imageSrc: 'assets/images/MOMENT_RIC-10_double-Tech-Black_1000x1000.png',
      modelName: t('RIC:modelName'),
      title: t('RIC:title'),
      description: t('RIC:description'),
      suitableFor: t('RIC:suitableFor'),
      src: 'mRic_c/Export-web_mRic_',
      limit: 111,
      slideLimit: 51,
    },
    [ProductType.BTE]: {
      imageSrc:
        'assets/images/MOMENT_BTE-13-D-double_Autumn-Beige_1000x1000.png',
      modelName: t('BTE:modelName'),
      title: t('BTE:title'),
      description: t('BTE:description'),
      suitableFor: t('BTE:suitableFor'),
      src: 'RIC-10_c/Export-web_RIC-10_' /*path without the extendsion 00001.png*/,
      limit: 112,
      slideLimit: 51,
    },
  };
};
