import React, { FC } from 'react';
import IconLogo from '../Icon/icons/icon-logo';

type Props = {
  fill: string;
};

export const Logo: FC<Props> = ({ fill }) => (
  <div className={`fill-${fill}`}>
    <IconLogo fill={fill} />
  </div>
);
