import { useTranslation } from "react-i18next";
import { NavigationBlock, ActionType } from "../utils/types";

export const useFrontPageLinks = (): NavigationBlock[] => { 
    const { t } = useTranslation();
    return [
    {
      imageSrc: 'assets/images/Widex_hearing_test_online_1000x1000.jpg',
      alt: '',
      text: t('frontPageNavigation:link1:text'),
      icon: 'IconHeadphone',
      type: ActionType.SOUNDDEMO,
    },
    {
      imageSrc: 'assets/images/widexsound-flatlay-1920-1080.jpg',
      alt: '',
      text: t('frontPageNavigation:link2:text'),
      icon: 'IconProducts',
      type: ActionType.PRODUCTS,
    },
    {
      imageSrc: 'assets/images/Lady.jpg',
      alt: '',
      text: t('frontPageNavigation:link3:text'),
      icon: 'IconTestimonials',
      type: ActionType.SOUNDEXPERIENCE,
    },
  ]};