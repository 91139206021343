import React from 'react';

const IconPause = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23,8h-2c-0.54,0-0.94,0.52-0.99,0.91L20,9v14c0,0.37,0.37,0.92,0.88,0.99L21,24h2c0.54,0,0.94-0.52,0.99-0.91  L24,23V9c0-0.37-0.37-0.92-0.88-0.99L23,8z M11,8H9C8.55,8,8.07,8.44,8.01,8.89L8,9v14c0,0.46,0.44,0.93,0.9,0.99L9,24h2  c0.45,0,0.93-0.44,0.99-0.89L12,23V9c0-0.45-0.44-0.93-0.89-0.99L11,8z M23,6c1.57,0,2.9,1.34,2.99,2.83L26,9v14  c0,1.51-1.27,2.9-2.83,2.99L23,26h-2c-1.57,0-2.9-1.34-2.99-2.83L18,23V9c0-1.51,1.27-2.9,2.83-2.99L21,6H23z M11,6  c1.54,0,2.9,1.31,2.99,2.83L14,9v14c0,1.54-1.31,2.9-2.83,2.99L11,26H9c-1.54,0-2.9-1.31-2.99-2.83L6,23V9  c0-1.54,1.31-2.9,2.83-2.99L9,6H11z" />
    </svg>
  );
};

export default IconPause;
