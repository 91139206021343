import { FC } from 'react';
import { ActionType } from '../../../utils/types';
import { Header } from '../../organisms/Header/Header';
import { ImageBlock } from '../../cells/ImageBlock/ImageBlock';
import { Button } from '../../molecules/Button/Button';
import { useFrontPageLinks } from '../../../hooks/useFrontPageLinks';
import { getLanguage } from '../../../utils/language';

type FrontpageProps = {
  isCacheReady?: boolean;
  cachingProgress?: number;
  setNavigatedLink: (link: ActionType) => void;
};

export const FrontPage: FC<FrontpageProps> = ({ setNavigatedLink }) => {
  window.dataLayer = window.dataLayer || [];

  return (
    <>
      <div className="flex flex-col h-full min-h-[800px] bg-darkGrey">
        <Header showBackButton={false} setNavigatedLink={setNavigatedLink} />
        <div className="grid w-full grid-cols-1 overflow-hidden sm:grid-cols-3 grow">
          {useFrontPageLinks().map((singleLink, index) => {
            return (
              <div
                key={index}
                className="cursor-pointer"
                onClick={() => {
                  setNavigatedLink(singleLink.type);
                  window.dataLayer.push({
                    event: 'MainMenuOptionClick',
                    menuOption: singleLink.text.toLowerCase(),
                    language: getLanguage().label,
                  });
                }}
              >
                <ImageBlock key={index} imageSrc={singleLink.imageSrc}>
                  <div className="z-10 flex justify-center w-full pb-5 sm:pb-10">
                    <Button
                      text={singleLink.text}
                      icon={{ name: singleLink.icon, align: 'left' }}
                      flexWidth={true}
                      large={true}
                    />
                  </div>
                </ImageBlock>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
