import React from 'react';
import { IconSVGProps } from '../Icon';

const IconLogo = (props: IconSVGProps) => {
  const { fill } = props;
  return (
    <svg fill={fill} viewBox="0 0 107.7 31" xmlns="http://www.w3.org/2000/svg">
      <g id="XMLID_78_">
        <g id="XMLID_82_">
          <path
            id="XMLID_88_"
            d="M39.5,7.1h-1.9c-0.6,0-1.1,0.5-1.1,1.1v16.1c0,0.6,0.5,1.1,1.1,1.1h1.9c0.6,0,1.1-0.5,1.1-1.1V8.2
                        C40.6,7.6,40.1,7.1,39.5,7.1z"
          />
          <path
            id="XMLID_85_"
            d="M61.4,8.2c-1.5-0.7-3.2-1.1-5.1-1.1h-9.1c-0.6,0-1.1,0.5-1.1,1.1v16.1c0,0.6,0.5,1.1,1.1,1.1h9.1
                        c1.9,0,3.6-0.4,5.1-1.1c1.5-0.8,2.7-1.8,3.5-3.2c0.8-1.4,1.3-3,1.3-4.8s-0.4-3.4-1.3-4.8C64.1,10.1,62.9,9,61.4,8.2z M62.1,16.3
                        c0,1.1-0.2,2.1-0.7,2.9c-0.5,0.8-1.2,1.5-2.1,1.9c-0.9,0.5-2,0.7-3.2,0.7h-5.9V10.7h5.9c1.2,0,2.3,0.2,3.2,0.7
                        c0.9,0.4,1.6,1.1,2.1,1.9C61.9,14.2,62.1,15.1,62.1,16.3z"
          />
          <path
            id="XMLID_84_"
            d="M85.1,21.8H74.2v-3.9h9.5c0.6,0,1.1-0.5,1.1-1.1v-1.3c0-0.6-0.5-1.1-1.1-1.1h-9.5v-3.7h10.5
                        c0.6,0,1.1-0.5,1.1-1.1V8.2c0-0.6-0.5-1.1-1.1-1.1H71.2c-0.6,0-1.1,0.5-1.1,1.1v16.1c0,0.6,0.5,1.1,1.1,1.1h13.9
                        c0.6,0,1.1-0.5,1.1-1.1v-1.4C86.2,22.3,85.7,21.8,85.1,21.8z"
          />
          <path
            id="XMLID_83_"
            d="M107.5,23.9l-6.8-7.9l6.4-7.4c0.2-0.3,0.3-0.6,0.1-1c-0.2-0.3-0.5-0.5-0.8-0.5h-1.8
                        c-0.5,0-1.1,0.2-1.4,0.6l-4.8,5.7l-4.9-5.7c-0.3-0.4-0.9-0.6-1.4-0.6h-2c-0.4,0-0.7,0.2-0.8,0.5c-0.2,0.3-0.1,0.7,0.1,1l6.4,7.5
                        L89,23.9c-0.2,0.3-0.3,0.6-0.1,1c0.2,0.3,0.5,0.5,0.8,0.5h2c0.5,0,1.1-0.2,1.4-0.7l5.1-6.1l5.2,6.1c0.3,0.4,0.9,0.6,1.4,0.6h2
                        c0.4,0,0.7-0.2,0.8-0.5C107.8,24.5,107.7,24.2,107.5,23.9z"
          />
        </g>
        <g id="XMLID_79_">
          <path
            id="XMLID_81_"
            d="M5.8,7.1c1.6,0,3,1.1,3.4,2.7l1.2,5.1c0.2,0.7,1.2,0.7,1.3,0l1.5-6.7c0.1-0.6,0.7-1.1,1.3-1.1h2
                        c0.6,0,1.2,0.4,1.3,1.1l1.5,6.7c0.2,0.7,1.2,0.7,1.3,0l1.2-5.1c0.4-1.6,1.8-2.7,3.4-2.7h5C29.3,2.5,26.5,0,15.5,0
                        C4.6,0,1.8,2.5,0.7,7.1H5.8z"
          />
          <path
            id="XMLID_80_"
            d="M30.9,10.7h-3.6c-0.6,0-1.2,0.4-1.3,1.1L23,24.3c-0.1,0.6-0.7,1.1-1.3,1.1h-2.5c-0.6,0-1.2-0.4-1.3-1.1
                        l-1.6-7c-0.2-0.7-1.2-0.7-1.3,0l-1.6,7c-0.1,0.6-0.7,1.1-1.3,1.1H9.4c-0.6,0-1.2-0.4-1.3-1.1L5.1,11.8c-0.1-0.6-0.7-1.1-1.3-1.1
                        H0.2c0,0.1,0,0.2,0,0.4C0,12.2,0,14.4,0,15.5c0,1.2,0,3.4,0.1,4.4C0.8,27,1.7,31,15.5,31c13.8,0,14.7-4,15.4-11.1
                        c0.1-1.1,0.1-3.3,0.1-4.4c0-1.2,0-3.4-0.1-4.4C30.9,11,30.9,10.8,30.9,10.7z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconLogo;
