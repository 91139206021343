import { useTranslation } from 'react-i18next';
import { LANGUAGES, LANGUAGE_CODE_TYPE, getLanguage } from '../utils/language';
import { SoundExperienceBlock } from '../utils/types';

export const useSoundExperienceLinks = (
  mediaAssetsLangCode: LANGUAGE_CODE_TYPE,
): SoundExperienceBlock[] => {
  const { t } = useTranslation();
  const language = getLanguage().code;
  const basePeople = [
    {
      imageSrc: 'assets/images/widex-sa-dominique.jpg',
      videoSrc: `assets/videos/${mediaAssetsLangCode}/dominique.mp4`,
      alt: '',
      name: t('soundAmbassadors:person1:name'),
      role: t('soundAmbassadors:person1:role'),
      description: t('soundAmbassadors:person1:description'),
    },
    {
      imageSrc: 'assets/images/widex-sa-ida.jpg',
      videoSrc: `assets/videos/${mediaAssetsLangCode}/ida.mp4`,
      alt: '',
      name: t('soundAmbassadors:person3:name'),
      role: t('soundAmbassadors:person3:role'),
      description: t('soundAmbassadors:person3:description'),
    },
  ];

  const alanCross = {
    imageSrc: 'assets/images/widex-sa-alan_cross.jpg',
    videoSrc: `assets/videos/${mediaAssetsLangCode}/alancross.mp4`,
    alt: '',
    name: t('soundAmbassadors:person2:name'),
    role: t('soundAmbassadors:person2:role'),
    description: t('soundAmbassadors:person2:description'),
  };

  const brianJackson = {
    imageSrc: 'assets/images/widex-sa-brian.jpg',
    videoSrc: `assets/videos/${mediaAssetsLangCode}/brian.mp4`,
    alt: '',
    name: t('soundAmbassadors:person4:name'),
    role: t('soundAmbassadors:person4:role'),
    description: t('soundAmbassadors:person4:description'),
  };

  const steveLuthake = {
    imageSrc: 'assets/images/widex-sa-steve.jpg',
    videoSrc: `assets/videos/${mediaAssetsLangCode}/steve.mp4`,
    alt: '',
    name: t('soundAmbassadors:person5:name'),
    role: t('soundAmbassadors:person5:role'),
    description: t('soundAmbassadors:person5:description'),
  };

  if (language === LANGUAGES.it.code) {
    basePeople.push(steveLuthake);
  } else if (language === LANGUAGES.de.code) {
    basePeople.push(brianJackson);
  } else {
    basePeople.push(alanCross);
  }

  return basePeople;
};
