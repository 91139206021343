import {
  createRef,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ActionType } from '../../../utils/types';
import { Header } from '../../organisms/Header/Header';
import { ImageBlock } from '../../cells/ImageBlock/ImageBlock';
import { Button } from '../../molecules/Button/Button';
import { Heading } from '../../atoms/Heading/Heading';
import { Video } from '../../molecules/Video/Video';
import { useSoundExperienceLinks } from '../../../hooks/useSoundExperienceLinks';
import { getLanguage, getMediaAssetsLangCode } from '../../../utils/language';

type Props = {
  setNavigatedLink: (link: ActionType) => void;
};

export const SoundExperience: FC<Props> = ({ setNavigatedLink }) => {
  window.dataLayer = window.dataLayer || [];
  const { t } = useTranslation();
  const mediaAssetsLangCode = getMediaAssetsLangCode();
  // Set an array of ref objects to find the height of each item
  const contentRefs = useRef<RefObject<HTMLInputElement>[]>(
    useSoundExperienceLinks(mediaAssetsLangCode).map(() => createRef()),
  );
  const [showVideo, setShowVideo] = useState<string | null>(null);
  const [videoPercentageProgress, setVideoPercentageProgress] =
    useState<number>(0);
  const [minHeight, setMinHeight] = useState<number | null>(null);

  const handleMinHeight = useCallback(() => {
    const heights = contentRefs.current.map(({ current }) =>
      current ? current.clientHeight : 0,
    );
    setMinHeight(Math.max(...heights));
  }, []);

  // Set initial min height and event listener upon window resize
  useEffect(() => {
    handleMinHeight();

    window.addEventListener('resize', handleMinHeight);

    return () => {
      window.removeEventListener('resize', handleMinHeight);
    };
  }, [handleMinHeight]);

  // Set value for event tracking
  const videoProgress = useMemo(() => {
    if (!showVideo) {
      setVideoPercentageProgress(0);
      return 'play';
    }

    if (videoPercentageProgress > 99.5) {
      return '100%';
    }

    if (videoPercentageProgress > 50) {
      return '50%';
    }

    return 'play';
  }, [showVideo, videoPercentageProgress]);

  return (
    <>
      {showVideo && (
        <Video
          videoLink={showVideo}
          closeVideo={() => setShowVideo(null)}
          handleProgress={(perc) => setVideoPercentageProgress(perc)}
        />
      )}

      <div
        className={`flex flex-col h-full min-h-[800px] bg-darkGrey ${
          showVideo ? 'overflow-y-none' : ''
        }`}
      >
        <Header showBackButton={true} setNavigatedLink={setNavigatedLink} />

        <div className="grid w-full grid-cols-1 overflow-hidden sm:grid-cols-3 grow">
          {useSoundExperienceLinks(mediaAssetsLangCode).map(
            ({ imageSrc, videoSrc, name, role, description }, index) => (
              <div key={index} className="min-h-[400px]">
                <ImageBlock imageSrc={imageSrc}>
                  <div className="z-10 w-full p-4">
                    <div className="flex flex-col justify-between w-full h-full p-4 bg-dark bg-opacity-80">
                      <span style={{ height: `${minHeight}px ` }}>
                        <div ref={contentRefs.current[index]}>
                          <Heading
                            level="h3"
                            uppercase={true}
                            color="light"
                            className="pb-1"
                          >
                            {name}
                          </Heading>
                          <Heading level="h6" color="light" className="pb-4">
                            {role}
                          </Heading>
                          <Heading
                            level="h4"
                            color="light"
                            className="hidden pb-5 sm:block"
                          >
                            {description}
                          </Heading>
                        </div>
                      </span>
                      <div>
                        <Button
                          text={t('seeVideo')}
                          icon={{
                            name: 'IconPlay',
                            align: 'right',
                          }}
                          onClick={() => {
                            setShowVideo(videoSrc);
                            window.dataLayer.push({
                              event: 'SoundAmbassadorVideoEvent',
                              soundAmbassador: name,
                              videoProgress: videoProgress,
                              language: getLanguage().label,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ImageBlock>
              </div>
            ),
          )}
        </div>
      </div>
    </>
  );
};
