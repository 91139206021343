import React from 'react';
import { IconSVGProps } from '../Icon';

const IconStop = ({ small }: IconSVGProps) => {
  return (
    <svg
      width={small ? '24' : '32'}
      height={small ? '24' : '32'}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeWidth={small ? '0.5' : '0'}
        d="M9,8h14c0.55,0,1,0.45,1,1v14c0,0.55-0.45,1-1,1H9c-0.55,0-1-0.45-1-1V9C8,8.45,8.45,8,9,8z M23,6H9  C7.34,6,6,7.34,6,9v14c0,1.66,1.34,3,3,3h14c1.66,0,3-1.34,3-3V9C26,7.34,24.66,6,23,6z"
      />
    </svg>
  );
};

export default IconStop;
