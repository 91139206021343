import { Name as IconName } from '../components/atoms/Icon/Icon';

export type NavigationBlock = {
  imageSrc: string;
  alt: string;
  text: string;
  icon: IconName;
  type: ActionType;
};

export type SoundExperienceBlock = {
  imageSrc: string;
  videoSrc: string;
  alt: string;
  name: string;
  role: string;
  description: string;
};

export type Product = {
  imageSrc: string;
  modelName: string;
  title: string;
  description: string;
  suitableFor: string;
  src: string;
  limit: number;
  slideLimit: number;
};

export type IActionButton = {
  iconText: string;
  type: ActionType;
  size?: ActionSize;
};

export enum ActionType {
  FRONTPAGE = 'Frontpage',
  SOUNDEXPERIENCE = 'SoundExperience',
  SOUNDDEMO = 'SoundDemo',
  PRODUCTS = 'Products',
  EXPANDVIEW = 'ExpandView',
}

export enum SoundButtonType {
  SPEECH = 'Speech in Restaurant',
  MUSIC = 'Music',
  DINNER = 'Dinner',
  NATURE = 'Nature',
  UNDEFINED = 'Undefined',
  ORCHESTRA = 'Orchestra',
}

export enum ActionSize {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
}

export enum ProductType {
  CIC = 'CIC',
  ITE = 'ITE',
  RIC = 'RIC',
  BTE = 'BTE',
}
