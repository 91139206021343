import React from 'react';
import { IconSVGProps } from '../Icon';

const IconHeadphone = ({ small }: IconSVGProps) => {
  return (
    <svg
      width={small ? '24' : '32'}
      height={small ? '24' : '32'}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeWidth={small ? '0.5' : '0'}
        d="M6,22c0,1.82,1.18,3,3,3s3-1.18,3-3v-4c0-1.88-1.52-3-3-3c-0.34,0-0.68,0.06-1,0.18V15c0-4.69,3.31-7,8-7  s8,2.31,8,7v0.18C23.68,15.06,23.34,15,23,15c-1.48,0-3,1.12-3,3v4c0,1.82,1.18,3,3,3c1.82,0,3-1.18,3-3v-7c0-5.79-4.21-9-10-9  S6,9.21,6,15V22z M9,17c0.35,0,1,0.21,1,1v4c0,0.52-0.12,1-1,1s-1-0.48-1-1v-4C8,17.21,8.65,17,9,17z M24,22c0,0.52-0.12,1-1,1  s-1-0.48-1-1v-4c0-0.79,0.65-1,1-1s1,0.21,1,1V22z"
      />
    </svg>
  );
};

export default IconHeadphone;
