import React, { FC } from 'react';
import { classnames } from 'tailwindcss-classnames';
import {
  textColor,
  fontWeight,
  textAlign,
  textTransform,
  fontSize,
  transitionTimingFunction,
  transitionDuration,
  fontFamily,
} from '../../../tailwindcss-types';

type Level = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type Color = 'dark' | 'light' | 'dark-grey' | 'medium-grey';
type Alignment = 'left' | 'center' | 'right';
type Weight = 'semibold' | 'normal';

export type Props = {
  /**
   * Heading level
   */
  level?: Level;
  /**
   * Heading alignment
   */
  align?: Alignment;
  /**
   * Color of heading
   */
  color?: Color;
  /**
   * Normal or semibold
   */
  weight?: Weight;
  /**
   * Flag for uppercase
   */
  uppercase?: boolean;
  /**
   * Flag for line clamping
   */
  clamp?: boolean;
  /**
   * Additionally passed classes, i.e. padding
   */
  className?: string;
};

export const getClasses = (
  level: Level,
  align: Alignment,
  color: Color,
  weight: Weight,
  uppercase: boolean | undefined,
  clamp: boolean | undefined,
  isOnMobile?: boolean,
) => {
  const responsiveClamping = isOnMobile ? 'line-clamp-1' : 'line-clamp-3';

  return (
    classnames(
      fontFamily({
        'font-montserrat': level === 'h1',
        'font-notosans': level !== 'h1',
      }),
      fontSize({
        'text-medium': level === 'h1',
        'text-normal': level === 'h2' || level === 'h3',
        'text-small': level === 'h5' || level === 'h4',
        'text-xSmall': level === 'h6',
        'sm:text-xlarge': level === 'h1',
        'sm:text-large': level === 'h2',
        'sm:text-medium': level === 'h3',
        'sm:text-normal': level === 'h4',
      }),
      textColor({
        'text-light': color === 'light',
        'text-mediumGrey': color === 'medium-grey',
        'text-dark': color === 'dark',
        'text-darkGrey': color === 'dark-grey',
      }),
      textAlign({
        'text-center': align === 'center',
        'text-right': align === 'right',
        'text-left': align === 'left',
      }),
      textTransform({
        uppercase: uppercase,
        'normal-case': !uppercase,
      }),
      fontWeight({
        'font-semibold': weight === 'semibold',
        'font-normal': weight === 'normal',
      }),
      transitionDuration('duration-300'),
      transitionTimingFunction('ease-in-out'),
    ) + ` ${clamp ? responsiveClamping : 'line-clamp-none'}`
  );
}
    
export const Heading: FC<Props> = ({
  level = 'h1',
  align = 'left',
  color = 'light',
  weight = 'normal',
  uppercase,
  clamp,
  className,
  children,
}) => {
  const HeadingLevel = level;

  return (
    <HeadingLevel
      className={`${getClasses(
        level,
        align,
        color,
        weight,
        uppercase,
        clamp,
      )} ${className}`}
    >
      {children}
    </HeadingLevel>
  );
};
