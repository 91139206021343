import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../../hooks/useWindowSize';
import {
  Heading,
  getClasses as getHeadingClasses,
  Props as HeadingProps,
} from '../../atoms/Heading/Heading';

interface Props extends HeadingProps {
  text: string;
}

export const CollapsibleHeading: FC<Props> = ({
  text,
  level = 'h1',
  align = 'left',
  color = 'light',
  weight = 'normal',
  uppercase,
}) => {
  const { t } = useTranslation();
  const HeadingLevel = level;
  const [clamped, setClamped] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const { width } = useWindowSize();
  const mobileBreakpoint = width < 768;
  
  useEffect(() => {
    const showAndClamped = text.length >= 100 && width <= 1440;
    setShowButton(showAndClamped);
    setClamped(showAndClamped);
  }, [text, width]);

  return (
    <div>
      <HeadingLevel
        className={getHeadingClasses(
          level,
          align,
          color,
          weight,
          uppercase,
          clamped,
          mobileBreakpoint,
        )}
      >
        {text}
      </HeadingLevel>

      {showButton && (
        <button
          className="w-full"
          onClick={() => {
            setClamped(!clamped);
          }}
        >
          <Heading
            level={level}
            align={mobileBreakpoint ? 'center' : 'left'}
            color={color}
            weight={weight}
            uppercase={uppercase}
            className={'underline hover:opacity-50 pb-1'}
          >
            {clamped ? t('readMore') : t('readLess')}
          </Heading>
        </button>
      )}
    </div>
  );
};
