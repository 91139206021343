import React from 'react';
import { IconSVGProps } from '../Icon';

const IconTestimonials = ({ small }: IconSVGProps) => {
  return (
    <svg
      width={small ? '24' : '32'}
      height={small ? '24' : '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9424 7.98828C9.13665 7.98828 6.85645 10.3203 6.85645 13.1898C6.85645 16.068 9.13665 18.3914 11.9424 18.3914C14.7482 18.3914 17.0284 16.068 17.0284 13.1898C17.0284 10.3203 14.7482 7.98828 11.9424 7.98828ZM11.9424 16.6575C10.0691 16.6575 8.55177 15.1057 8.55177 13.1898C8.55177 11.2739 10.0691 9.72213 11.9424 9.72213C13.8157 9.72213 15.3331 11.2739 15.3331 13.1898C15.3331 15.1057 13.8157 16.6575 11.9424 16.6575Z"
        fill="currentColor"
      />
      <path
        d="M19.1562 14.1777C20.8939 13.8916 21.9027 13.016 22.0383 11.4382C22.0468 11.3169 22.1485 11.2128 22.2672 11.2128H27.5396C28.2516 11.2128 28.828 10.6233 28.828 9.89509V5.31773C28.828 4.58951 28.2516 4 27.5396 4H17.8085C17.0964 4 16.52 4.58951 16.52 5.31773V7.28565"
        stroke="currentColor"
        strokeWidth="1.84423"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 23.7575C5.41559 21.4861 7.806 19.9863 10.5185 19.9863C14.8585 19.9863 18.3848 23.8095 18.3848 28.5342"
        stroke="currentColor"
        strokeWidth="1.84423"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M22.8267 8.70846C23.2948 8.70846 23.6743 8.32033 23.6743 7.84154C23.6743 7.36275 23.2948 6.97461 22.8267 6.97461C22.3585 6.97461 21.979 7.36275 21.979 7.84154C21.979 8.32033 22.3585 8.70846 22.8267 8.70846Z"
        fill="currentColor"
      />
      <path
        d="M25.6494 8.70846C26.1176 8.70846 26.4971 8.32033 26.4971 7.84154C26.4971 7.36275 26.1176 6.97461 25.6494 6.97461C25.1813 6.97461 24.8018 7.36275 24.8018 7.84154C24.8018 8.32033 25.1813 8.70846 25.6494 8.70846Z"
        fill="currentColor"
      />
      <path
        d="M20.0039 8.70846C20.4721 8.70846 20.8516 8.32033 20.8516 7.84154C20.8516 7.36275 20.4721 6.97461 20.0039 6.97461C19.5358 6.97461 19.1562 7.36275 19.1562 7.84154C19.1562 8.32033 19.5358 8.70846 20.0039 8.70846Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconTestimonials;
