const IconGlobe = () => {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <path d="M16 4C9.37 4 4 9.37 4 16s5.37 12 12 12 12-5.37 12-12S22.63 4 16 4zm9.16 8h-3.47c-.5-2.1-1.39-3.99-2.58-5.5 2.71.89 4.91 2.91 6.05 5.5zm.84 4c0 .68-.07 1.35-.2 2h-3.75c.08-.66.12-1.32.12-2 0-.68-.04-1.34-.12-2h3.75c.13.65.2 1.32.2 2zm-10 9.75c-1.68-1.25-2.94-3.3-3.62-5.75h7.23c-.67 2.44-1.93 4.5-3.61 5.75zM11.98 18a14.6 14.6 0 0 1-.14-2c0-.68.06-1.35.14-2h8.05c.08.65.14 1.32.14 2 0 .68-.06 1.35-.14 2h-8.05zM6 16c0-.68.07-1.35.2-2h3.75c-.08.66-.12 1.32-.12 2 0 .68.04 1.34.12 2H6.2a10.2 10.2 0 0 1-.2-2zm10-9.75c1.68 1.25 2.95 3.3 3.62 5.75h-7.24c.67-2.45 1.94-4.5 3.62-5.75zm-3.11.25C11.7 8 10.81 9.9 10.31 12H6.84c1.14-2.59 3.34-4.61 6.05-5.5zM6.84 20h3.47c.51 2.1 1.4 4 2.59 5.5-2.72-.89-4.92-2.91-6.06-5.5zm12.26 5.5c1.19-1.51 2.08-3.4 2.59-5.5h3.47a10.055 10.055 0 0 1-6.06 5.5z" />
    </svg>
  );
};

export default IconGlobe;
