import { FC, useEffect, useRef, useState } from 'react';
import { Icon } from '../../atoms/Icon/Icon';

type Props = {
  videoLink: string;
  closeVideo: () => void;
  handleProgress: (progress: number) => void;
};

export const Video: FC<Props> = ({ videoLink, closeVideo, handleProgress }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  const onTimeUpdate = () => {
    let ref = videoRef.current;
    if (ref) {
      setDuration(ref.duration);
      setCurrentTime(ref.currentTime);
    }
  };

  useEffect(() => {
    handleProgress((currentTime / duration) * 100);
  }, [currentTime, duration, handleProgress]);

  return (
    <div className="bg-dark fixed inset-0 z-20 flex flex-col items-end justify-center w-full h-full">
      <button
        onClick={closeVideo}
        className="fill-light hover:fill-lightGrey fixed top-0 z-10 flex items-center justify-center w-10 h-10 duration-300 ease-in-out"
      >
        <Icon name="IconClose" />
      </button>
      <div className="w-full">
        {videoLink && (
          <video
            width="100%"
            autoPlay
            controls
            controlsList="nodownload"
            ref={videoRef}
            onTimeUpdate={onTimeUpdate}
          >
            <source src={videoLink} type="video/mp4" />
            <p>Sorry, your browser doesn't support embedded videos.</p>
          </video>
        )}
      </div>
    </div>
  );
};
