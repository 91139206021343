import { useState } from 'react';
import './App.css';
import { FrontPage } from './components/species/FrontPage/FrontPage';
import { ProductGuide } from './components/species/ProductGuide/ProductGuide';
import { SoundExperience } from './components/species/SoundExperience/SoundExperience';
import { products, soundDemo } from './data/data';
import { ActionType } from './utils/types';
import './i18n';
import { SoundDemo } from './components/species/SoundDemo/SoundDemo';

declare global {
  interface Window {
    dataLayer: Array<unknown>;
  }
}

function App() {
  const [selectedLink, setSelectedLink] = useState<ActionType>(
    ActionType.FRONTPAGE,
  );
  return (
    <>
      {selectedLink === ActionType.FRONTPAGE && (
        <FrontPage setNavigatedLink={setSelectedLink} />
      )}
      {selectedLink === ActionType.PRODUCTS && (
        <ProductGuide products={products} setNavigatedLink={setSelectedLink} />
      )}
      {selectedLink === ActionType.SOUNDDEMO && (
        <SoundDemo
          scenarioButtons={soundDemo}
          setNavigatedLink={setSelectedLink}
        />
      )}
      {selectedLink === ActionType.SOUNDEXPERIENCE && (
        <SoundExperience setNavigatedLink={setSelectedLink} />
      )}
    </>
  );
}

export default App;
