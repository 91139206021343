import { FC } from 'react';

export type ImageBackgroundProps = {
  src: string;
  position?: 'top' | 'center' | 'bottom';
  blur?: boolean;
};

export const ImageBackground: FC<ImageBackgroundProps> = ({ src, blur }) => (
  <div className="absolute flex items-end w-full h-full overflow-hidden">
    <span
      className="absolute inset-0 object-cover object-center w-full h-full bg-center bg-no-repeat bg-cover"
      style={{
        backgroundImage: `url(${src})`,
        filter: blur ? 'blur(10px)' : 'none',
      }}
    />
    <div className="absolute w-full h-1/2 bg-gradient-to-t from-black70" />
    <div className="absolute w-full h-full bg-black bg-opacity-70" />
  </div>
);
