import { FC } from 'react';
import { SoundButtonType } from '../../../utils/types';
import { ImageBackgroundProps } from '../../molecules/ImageBackground/ImageBackground';
import { classnames, zIndex } from 'tailwindcss-classnames';
import { getLanguage } from '../../../utils/language';
import {
  borderStyle,
  backgroundColor,
  textColor,
  padding,
  borderWidth,
  display,
  alignItems,
  flexDirection,
  justifyContent,
  width,
  position,
  inset,
  transitionDuration,
  transitionTimingFunction,
} from '../../../tailwindcss-types';

type Props = {
  text: string;
  // Sound scenario type
  type: SoundButtonType;
  // Which sound file
  audioType: boolean;
  isSelectedSound: boolean;
  setSelectedSound: (type: SoundButtonType) => void;
  backgroundImage?: ImageBackgroundProps;
};

export const SoundButton: FC<Props> = ({
  text,
  type,
  isSelectedSound,
  audioType,
  setSelectedSound,
  backgroundImage,
}) => {
  window.dataLayer = window.dataLayer || [];

  const buttonClassses = classnames(
    padding('py-3.5'),
    backgroundColor('bg-dark', 'hover:bg-light'),
    display('flex'),
    position('relative'),
    width('w-full'),
    borderStyle('border-solid'),
    borderWidth('border-2'),
    textColor('text-light', 'hover:text-dark'),
    inset('inset-0'),
    transitionDuration('duration-300'),
    transitionTimingFunction('ease-in-out'),
  );

  const contentClasses = classnames(
    padding('px-2', 'xs:px-3.5'),
    display('flex'),
    flexDirection('flex-row'),
    alignItems('items-center'),
    justifyContent('justify-center'),
    width('w-full'),
    zIndex('z-10'),
  );

  return (
    <button
      className={`${buttonClassses} group`}
      onClick={() => {
        setSelectedSound(type);
        window.dataLayer.push({
          event: 'SoundDemoButtonClick',
          soundDemo: type,
          // soundDemoAction: isPlaying ? 'play' : 'stop',
          soundDemoWidexSound: audioType ? 'on' : 'off',
          language: getLanguage().label,
        });
      }}
    >
      <span
        style={{
          backgroundImage: `url(${backgroundImage?.src})`,
          backgroundPositionY: backgroundImage?.position,
        }}
        className={`absolute inset-0 w-full h-full bg-center bg-cover opacity-${
          isSelectedSound ? '100' : '10'
        } z-0`}
      />
      {isSelectedSound && (
        <span className="bg-light opacity-40 absolute inset-0 z-2" />
      )}
      <span className={contentClasses}>
        <p
          className={`z-0 text-xSmall sm:text-normal font-semibold text-center${
            isSelectedSound ? ' text-dark' : ''
          }`}
        >
          {text}
        </p>
      </span>
    </button>
  );
};
