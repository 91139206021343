import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionType } from '../../../utils/types';
import { LANGUAGES, getLanguage } from '../../../utils/language';
import { Heading } from '../../atoms/Heading/Heading';
import { Icon } from '../../atoms/Icon/Icon';
import { Logo } from '../../atoms/Logo/Logo';
import { Button } from '../../molecules/Button/Button';
import {
  DropdownMenu,
  DropdownMenuItem,
} from '../../molecules/Dropdown/DropdownMenu';
import { CachingStateInformation } from '../../cells/CachingInformation/CachingInformation';

type Props = {
  /**
   * Setter of back to front-page
   */
  setNavigatedLink: (type: ActionType) => void;
  /**
   * Flag to show back button
   */
  showBackButton: boolean;
};

const lanuageArray = Object.values(LANGUAGES);

export const Header: FC<Props> = ({
  setNavigatedLink,
  showBackButton = true,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CachingStateInformation />
      <div className="relative flex flex-row items-center w-full h-10 bg-darkGrey">
        {showBackButton && (
          <div
            onClick={() => {
              setNavigatedLink(ActionType.FRONTPAGE);
            }}
            className="absolute flex flex-row items-center justify-center gap-1 duration-300 ease-in-out cursor-pointer sm:gap-2 fill-light left-2 sm:left-4 group hover:opacity-50"
          >
            <Icon name="IconArrowLeft" />
            <Heading level="h3" color="light">
              {t('back')}
            </Heading>
            <div className="w-4 h-[2px] bg-light left-[40px] sm:left-[44px] absolute bottom-0" />
          </div>
        )}

        <div
          onClick={() => {
            setNavigatedLink(ActionType.FRONTPAGE);
          }}
          className="absolute h-auto -translate-x-1/2 cursor-pointer w-11 right-1/2 left-1/2"
        >
          <Logo fill="lightGrey" />
        </div>

        <div className="absolute right-2 sm:right-4 z-10">
          <DropdownMenu
            renderButton={
              <Button
                isSwitcher
                text={getLanguage().label}
                icon={{ name: 'IconGlobe', align: 'left' }}
              />
            }
          >
            {lanuageArray
              .filter((language) => {
                return language !== getLanguage();
              })
              .map((language, i) => (
                <DropdownMenuItem language={language} key={i} />
              ))}
          </DropdownMenu>
        </div>
      </div>

      <div
        className={`font-montserrat w-full h-6 bg-lightGrey flex flex-col justify-center items-center uppercase text-normal text-dark`}
      >
        {t('beam')}
      </div>
    </>
  );
};
