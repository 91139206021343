import { FC, useCallback, useEffect, useState } from 'react';
import { Heading } from '../../atoms/Heading/Heading';

type ToggleSingle = {
  name: string;
  value: string;
};

type ToggleProps = {
  toggles: {
    toggleA: ToggleSingle;
    toggleB: ToggleSingle;
  };
  audioFile: string;
  setSelected: (value: string) => void;
};

export const Toggle: FC<ToggleProps> = ({
  toggles,
  audioFile,
  setSelected,
}) => {
  const { toggleA, toggleB } = toggles;
  const [activeTab, setActiveTab] = useState(audioFile);

  useEffect(() => {
    setActiveTab(audioFile);
  }, [audioFile]);

  const handleToggleAction = useCallback(
    ({ value }) => {
      setActiveTab(value);
      setSelected(value);
    },
    [setSelected],
  );

  const getToggle = useCallback(
    (toggle: ToggleSingle) => {
      const toggleIsActive = toggle.value === activeTab;

      return (
        <span className="flex items-center justify-center w-full cursor-pointer">
          <input
            type="radio"
            value={toggle.name}
            id={toggle.name}
            name="audio"
            checked={toggleIsActive}
            onChange={() => handleToggleAction(toggle)}
            className="w-0 h-0 opacity-0 focus:outline-none"
          />
          <label htmlFor={toggle.name} className="w-full cursor-pointer p-2">
            <Heading
              level="h3"
              align="center"
              weight={toggleIsActive ? 'semibold' : 'normal'}
              color={toggleIsActive ? 'dark' : 'medium-grey'}
            >
              {toggle.name}
            </Heading>
          </label>
        </span>
      );
    },
    [activeTab, handleToggleAction],
  );

  return (
    <div className="relative flex items-center justify-center my-5.5  w-11/12 sm:w-4/6 gap-2 border-2 border-lightGrey rounded-2xl">
      {getToggle(toggleA)}
      {getToggle(toggleB)}
      <span
        className={`rounded-2xl w-[calc(50%+16px)] h-[calc(100%+16px)] absolute -z-10 ease-in-out duration-300 ${
          activeTab === toggleA.value
            ? '-translate-x-[calc(50%-8px)]'
            : 'translate-x-[calc(50%-8px)]'
        } bg-lightGrey hover:bg-light`}
      />
    </div>
  );
};
