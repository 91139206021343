import { SoundButtonType } from './types';

export const getWidexSound = (type: SoundButtonType) => {
  switch (type) {
    case SoundButtonType.ORCHESTRA:
      return 'assets/widex-sounds/orchestra.mp3';
    case SoundButtonType.SPEECH:
      return 'assets/widex-sounds/speech.mp3';
    default:
      return 'assests/test.mp3';
  }
};
export const getNonWidexSound = (type: SoundButtonType) => {
  switch (type) {
    case SoundButtonType.ORCHESTRA:
      return 'assets/sounds/orchestra.mp3';
    case SoundButtonType.SPEECH:
      return 'assets/sounds/speech.mp3';
    default:
      return 'https://assets.coderrocketfuel.com/pomodoro-times-up.mp3';
  }
};
