import { ScenarioButton } from '../components/species/SoundDemo/SoundDemo';
import {
  ProductType,
  SoundButtonType,
} from '../utils/types';

export const products: ProductType[] = [
  ProductType.CIC,
  ProductType.ITE,
  ProductType.RIC,
  ProductType.BTE,
];

export const soundDemo: ScenarioButton[] = [
  {
    text: 'speech',
    type: SoundButtonType.SPEECH,
    image: {
      src: '/assets/images/speech-2.jpg',
      position: 'top',
      blur: true,
    },
  },
  {
    text: 'orchestra',
    type: SoundButtonType.ORCHESTRA,
    image: {
      src: '/assets/images/orchestra.jpg',
      position: 'center',
      blur: true,
    },
  },
];

