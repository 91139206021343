/* Smooth scroll with the library "smooth-scrollbar-react" is inspired by this project The https://idiotwu.github.io/smooth-scrollbar */
/* Animaton of images from slider input is inspired by this code pen https://codepen.io/jesus-rodriguez-dev/pen/MWwWBer*/
import { t } from 'i18next';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useCountUp } from 'react-countup';
import { Scrollbar } from 'smooth-scrollbar-react';
import { OverscrollEffect } from 'smooth-scrollbar/plugins/overscroll';
import classnames from 'tailwindcss-classnames';
import useWindowSize from '../../../hooks/useWindowSize';
import { height, inset, position, translate } from '../../../tailwindcss-types';
import { Product } from '../../../utils/types';
import { Heading } from '../../atoms/Heading/Heading';
import { Button } from '../../molecules/Button/Button';
import { CollapsibleHeading } from '../../molecules/CollapsibleHeading/CollapsibleHeading';
import { getLanguage } from '../../../utils/language';

interface ProductCanvasProps {
  product: Product;
}

export const ProductCanvas: FC<ProductCanvasProps> = ({ product }) => {
  window.dataLayer = window.dataLayer || [];
  const windowSize = useWindowSize();
  const mobileBreakpoint = windowSize.width < 768;
  const mobileBreakpointSmall = mobileBreakpoint && windowSize.height < 670;
  const product_limit = product.limit - 1;
  const product_length = product.limit;
  const slider_limit = product.slideLimit;

  const [frame, setFrame] = useState(0);

  const { countUp, start } = useCountUp({
    useEasing: false,
    start: 0,
    end: product_limit,
    delay: 1000,
    duration: 5,
  });
  const canvasRef = useRef(null);

  const images = useRef<CanvasImageSource[]>([]);

  const loadImage = useCallback(
    (index: number) => {
      const canvas = canvasRef.current as unknown as HTMLCanvasElement;
      if (!canvas) return;
      const context = canvas.getContext('2d');
      if (!context) return;

      var grd = context.createRadialGradient(
        canvas.width * 0.45,
        canvas.height * 0.48,
        0,
        canvas.width * 0.55,
        canvas.height * 0.52,
        300,
      );
      grd.addColorStop(0, '#82807f');
      grd.addColorStop(1, '#524e4c');

      // Fill with gradient
      context.setTransform(1, 0, 0, 1, 0, 0);
      context.clearRect(0, 0, canvas.width, canvas.height);
      if (images.current[index]) {
        context.drawImage(
          images.current[index],
          0,
          0,
          canvas.width,
          canvas.height,
        );
      } else {
        console.error(index, 'images');
        console.error(product_limit, 'limit');
      }
    },
    [images, product_limit],
  );

  const handleInputSlider = useCallback(
    (e: any) => {
      const val = Math.floor((e.offset.x / 1582) * product_limit);

      if (val > 0 && val < slider_limit) {
        setFrame(val);
        loadImage(val);
      }
    },
    [loadImage, setFrame, slider_limit, product_limit],
  );

  const handleStart = useCallback(
    (count: any) => {
      if (typeof count === 'string') {
        const val = Math.floor(frame + parseInt(count));
        const newFrame = val;
        if (newFrame > 0 && newFrame < product_length + 1) {
          loadImage(newFrame);
        }
      }
    },
    [loadImage, frame, product_length],
  );

  useEffect(() => {
    handleStart(countUp);
  }, [countUp, handleStart]);

  useEffect(() => {
    let counter: number = 0;
    for (let i = 1; i <= product_length; i++) {
      let str = i.toString().padStart(5, '0');

      const src = product.src;
      const url = `${src}${str}.png`;
      const image = new Image();
      image.src = url;
      image.addEventListener(
        'load',
        /*eslint no-loop-func: */
        () => {
          images.current[i] = image;
          if (i === 1) loadImage(1);
          if (i > counter) counter = i;
        },
      );
    }
  }, [handleInputSlider, images, loadImage, product, product_length]);

  const canvasClasses = classnames(
    position('absolute'),
    inset('sm:left-0'),
    translate({
      'translate-y-10pn': mobileBreakpoint && mobileBreakpointSmall,
    }),
    height({
      'h-2/3': mobileBreakpointSmall,
    }),
  );

  return (
    <>
      <canvas
        ref={canvasRef}
        className={canvasClasses}
        width={505}
        height={380}
      />
      <div className="max-w-1024 sm:max-h-440 z-6 relative w-screen h-full">
        <Scrollbar
          className="bg-transparent opacity-0"
          onScroll={handleInputSlider}
          alwaysShowTracks={false}
          plugins={{
            overscroll: {
              effect: 'bounce' as OverscrollEffect.BOUNCE,
              damping: 0.5,
            },
          }}
        >
          <div className="min-h-250 h-full">
            <div className="min-h-410 h-full w-[1582px] bg-transparent block relative" />
            {/* Kept as a debug code to see the scrollable area
            <span>
            <img src="https://raw.githubusercontent.com/idiotWu/react-smooth-scrollbar/master/test/your_diary.jpg" />
            </span>
            <span>
              <img src="https://raw.githubusercontent.com/idiotWu/react-smooth-scrollbar/master/test/your_diary.jpg" />
            </span>
            <span>
              <img src="https://raw.githubusercontent.com/idiotWu/react-smooth-scrollbar/master/test/your_diary.jpg" />
            </span>
            <span>
              <img src="https://raw.githubusercontent.com/idiotWu/react-smooth-scrollbar/master/test/your_diary.jpg" />
            </span> */}
          </div>
        </Scrollbar>
        <div
          className={`absolute right-0 flex flex-col justify-start sm:justify-between items-center sm:items-start w-full sm:h-full ${
            mobileBreakpointSmall ? 'py-3' : 'py-6'
          } px-4 sm:pr-10p sm:pl-0 duration-300 ease-in-out bottom-0 top-1/2 sm:bottom-auto sm:top-0 sm:w-1/2`}
          style={{
            zIndex: 80006,
          }}
        >
          <div className="flex flex-col w-full">
            <Heading
              level="h1"
              color="dark"
              align={mobileBreakpoint ? 'center' : 'left'}
              className="pb-3"
            >
              {`${product.title} (${product.modelName})`}
            </Heading>
            <CollapsibleHeading
              level="h4"
              color="dark"
              align={mobileBreakpoint ? 'center' : 'left'}
              className="pb-1"
              text={t(`${product.description}`)}
            />
            <Heading
              level="h6"
              color="dark-grey"
              align={mobileBreakpoint ? 'center' : 'left'}
              className="pb-3 sm:pb-5"
            >
              {t(`${product.suitableFor}`)}
            </Heading>
          </div>

          <div className="flex pb-5 lg:pb-0">
            <Button
              text={t('demoOnEar')}
              onClick={() => {
                start();
                window.dataLayer.push({
                  event: 'VisualDemoClick',
                  productGuide: product.modelName,
                  language: getLanguage().label,
                });
              }}
              icon={{
                name: 'IconPlay',
                align: 'right',
                small: true,
              }}
              dark={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
