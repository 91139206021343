import React, {
  cloneElement,
  ReactElement,
  HTMLAttributes,
  useMemo,
  forwardRef,
  Children,
  ReactNode,
  isValidElement,
  Fragment,
} from 'react';
import {
  useMenuState,
  Menu as ReakitMenu,
  MenuItem,
  MenuButton,
  MenuOptions,
} from 'reakit/Menu';
import classnames from 'classnames';
import some from 'lodash/some';
import flatten from 'lodash/flatten';
import { switchLanguage } from '../../../utils/language';

interface MenuProps extends MenuOptions, HTMLAttributes<HTMLElement> {
  children: ReactNode;
}

interface DropdownMenuProps extends HTMLAttributes<HTMLElement> {
  /**
   * The React element that displays the menu when clicked.
   */
  renderButton: ReactElement;
}

const Menu = forwardRef<HTMLDivElement, MenuProps>(function Menu(
  {
    children,
    'aria-labelledby': ariaLabelledBy,
    'aria-label': ariaLabel,
    className,
    ...menu
  }: MenuProps,
  ref,
) {
  const [hasLeadingIcon, hasTrailingIcon] = useMemo(() => {
    const getPropsThroughFragments = (
      children: ReactNode,
    ): Record<string, unknown>[] =>
      flatten(
        Children.toArray(children).map((child) =>
          isValidElement(child)
            ? child.type === Fragment
              ? getPropsThroughFragments(child.props.children)
              : child.props
            : {},
        ),
      );

    const childProps = getPropsThroughFragments(children);

    return [
      some(childProps, 'icon'),
      some(childProps, (props) => props.children || props.selected),
    ];
  }, [children]);

  const alignment = useMemo(
    () => menu.placement.split('-')[1],
    [menu.placement],
  );

  const cloneThroughFragments = <TProps extends object>(
    children: ReactNode,
    props: TProps,
  ): ReactNode => {
    return Children.map(children, (child) =>
      isValidElement(child)
        ? child.type === Fragment
          ? cloneThroughFragments(child.props.children, props)
          : cloneElement(child, { ...props, ...child.props })
        : child,
    );
  };

  return (
    <ReakitMenu
      {...menu}
      className={classnames('echo-menu', 'echo-dropdown', className, {
        'echo-dropdown--lead-icon': hasLeadingIcon,
        'echo-dropdown--trail-icon': hasTrailingIcon,
        'echo-dropdown--up': alignment === 'end',
      })}
      ref={ref}
      aria-labelledby={ariaLabelledBy}
      aria-label={ariaLabel}
    >
      {children && cloneThroughFragments(children, menu)}
    </ReakitMenu>
  );
});

export const DropdownMenuItem = forwardRef<HTMLButtonElement, any>(
  function DropdownMenuItem(
    { children, selected, language, hide, ...other }: any,
    ref,
  ) {
    const menu = useMenuState();
    const { code, label } = language;

    return (
      <>
        <MenuItem
          {...menu}
          {...other}
          ref={ref}
          className={classnames('echo-menu__item', 'echo-dropdown__item')}
          onClick={() => {
            switchLanguage(code);
            hide();
          }}
        >
          {children ? (
            (props) => (
              <>
                <MenuButton {...menu} {...props}>
                  <span className="echo-dropdown__item-label">{label}</span>
                </MenuButton>
                <Menu {...menu} aria-label={label}>
                  {children}
                </Menu>
              </>
            )
          ) : (
            <>
              <span className="echo-dropdown__item-label">{label}</span>
            </>
          )}
        </MenuItem>
      </>
    );
  },
);

export const DropdownMenu = React.forwardRef<
  HTMLButtonElement,
  DropdownMenuProps
>(function DropdownMenu(
  { id, renderButton, children, className, ...other }: DropdownMenuProps,
  ref,
) {
  const menu = useMenuState({ baseId: id });

  const disclosureId = useMemo(() => {
    return renderButton.props.id || `${menu.baseId}-disclosure`;
  }, [renderButton, menu.baseId]);

  return (
    <>
      <MenuButton {...menu} {...renderButton.props} ref={ref} id={disclosureId}>
        {(props: Record<string, unknown>) => cloneElement(renderButton, props)}
      </MenuButton>
      <Menu
        {...menu}
        {...other}
        className={className}
        aria-labelledby={disclosureId}
      >
        {children}
      </Menu>
    </>
  );
});

export default DropdownMenu;
