import { FC, useEffect, useState } from 'react';
import {
  handleServiceWorkerState,
  isCachingFinishedOfCurrentLanguage,
} from './helper';
import { useTranslation } from "react-i18next";

//484 = bundleFiles + unlocalized content; 4 = localized content (videos)
export const TOTAL_NUMBER_OF_CACHED_ITEMS = 484 + 4;
//cache name of localized content
export const VIDEO_CACHE_IDENTIFIER = 'videos';
export const UNLOCALIZED_CACHE_IDENTIFIER = 'unlocalized-assets';

//caching states used for caching status informations in client
export const CACHING_MESSAGE = {
  //used for caching state handling on frontend
  start: 'Start Caching',
  bundleFiles: 'Finished caching bundle files.',
  unlocalizedContent: 'Finished caching unlocalized content.',
  localizedContent: 'Finished caching localized content.',
  reset: 'Reset Caching States',
  onlyForLogging: {
    //only for logging purposes
    startBundleFiles: 'Start caching bundle files.',
    startUnlocalizedContent: 'Start caching unlocalized content.',
    startLocalizedContent: 'Start caching localized content.',
  },
};

export type CachingStateType = {
  start: boolean;
  bundleFiles: boolean;
  unlocalizedContent: boolean;
  localizedContent: boolean;
  failedFetching: boolean;
};

export const CachingStateInformation: FC = () => {
  const { t } = useTranslation();
  const [cachingStatus, setCachingStatus] = useState<CachingStateType>({
    start: false,
    bundleFiles: false,
    unlocalizedContent: false,
    localizedContent: false,
    failedFetching: false,
  });

  //Method for service worker listener for useEffect
  const serviceWorkerListener = async (event: MessageEvent): Promise<void> => {
    if (window.navigator.onLine) {
      if (cachingStatus.failedFetching) {
        setCachingStatus((prev) => ({ ...prev, failedFetching: false }));
      }
      return await handleServiceWorkerState(event, setCachingStatus);
    } else {
      if (await isCachingFinishedOfCurrentLanguage()) {
        setCachingStatus({
          start: true,
          bundleFiles: true,
          unlocalizedContent: true,
          localizedContent: true,
          failedFetching: false,
        });
      } else {
        setCachingStatus((prev) => ({ ...prev, failedFetching: true }));
      }
    }
  };

  useEffect(() => {
    //Listen to service worker messages (=state changes)
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener(
        'message',
        serviceWorkerListener,
      );

      return () => {
        navigator.serviceWorker.removeEventListener(
          'message',
          serviceWorkerListener,
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    start: isCachingStarted,
    failedFetching: isFetchingFailed,
    ...rest
  } = cachingStatus;
  const cachingStatusArray = Object.values(rest).filter(Boolean);
  const isCachingFinished =
    isCachingStarted &&
    rest.bundleFiles &&
    rest.unlocalizedContent &&
    rest.localizedContent;

  const cachingStatusMessage = isFetchingFailed
    ? t('cachingMessage:fetchingFailed')
    : isCachingFinished
    ? t('cachingMessage:cachingFinished')
    : isCachingStarted
    ? t('cachingMessage:cachingStarted', {
        cachingStatus: cachingStatusArray.length,
      })
    : //No caching triggered (show nothing, e.g. on reload when everything is already cached)
      undefined;

  return cachingStatusMessage ? (
    <div
      className={`w-full bg-dark flex py-1 px-4 text-light text-center justify-center`}
    >
      {cachingStatusMessage}
    </div>
  ) : (
    <></>
  );
};
