import { FC, useState } from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import { useProducts } from '../../../utils/get-product';
import { ActionType, ProductType } from '../../../utils/types';
import { Heading } from '../../atoms/Heading/Heading';
import { Header } from '../../organisms/Header/Header';
import { ProductCanvas } from './ProductCanvas';
import { getLanguage } from '../../../utils/language';

type ProductGuideProps = {
  products: ProductType[];
  setNavigatedLink: (type: ActionType) => void;
};

export const ProductGuide: FC<ProductGuideProps> = ({
  products,
  setNavigatedLink,
}) => {
  window.dataLayer = window.dataLayer || [];
  const windowSize = useWindowSize();
  const mobileBreakpoint = windowSize.width < 768;
  const [selectedProduct, setSelectedProduct] = useState<ProductType>(
    ProductType.CIC,
  );
  const productsData = useProducts();

  return (
    <div className="flex flex-col h-full min-h-[800px] bg-darkGrey">
      <Header showBackButton={true} setNavigatedLink={setNavigatedLink} />

      <div className="grow flex flex-col">
        <div className="sm:items-center bg-gradient-to-b from-gradientGrey to-light flex justify-center h-full">
          <div
            className="sm:flex-row relative flex flex-col items-center w-screen h-full overflow-x-hidden overflow-y-scroll"
            style={{ WebkitOverflowScrolling: 'touch' }}
          >
            <div className="max-w-1024 relative m-auto">
              {products.map(
                (productType, idx) =>
                  selectedProduct === productType && (
                    <ProductCanvas
                      key={idx}
                      product={productsData[productType]}
                    />
                  ),
              )}
            </div>
          </div>
        </div>

        <div className="bg-light/40 flex items-center justify-center">
          {products.map((productType, idx) => {
            const { modelName, title, imageSrc } = productsData[productType];
            return (
              <button
                key={idx}
                type="button"
                className={`product-guide-button h-full w-1/4 pt-1 pb-4 flex flex-col justify-start items-center ${
                  selectedProduct === productType
                    ? 'bg-light'
                    : 'bg-transparent'
                }  duration-300 ease-in-out`}
                onClick={() => {
                  setSelectedProduct(productType);
                  window.dataLayer.push({
                    event: 'ProductGuideClick',
                    productGuide: modelName,
                    language: getLanguage().label,
                  });
                }}
              >
                <div className="w-9 h- 9 sm:w-17.5 sm:h-17.5 mb-1">
                  <img alt={modelName} src={imageSrc} />
                </div>
                {!mobileBreakpoint && (
                  <Heading level="h3" color="dark" align="center">
                    {title}
                  </Heading>
                )}
                <Heading level="h3" color="dark" align="center">
                  {mobileBreakpoint ? modelName : `(${modelName})`}
                </Heading>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
