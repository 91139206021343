import React from 'react';

const IconArrowLeft = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17,6.03c0.39,0.39,0.39,1.02,0,1.41l-7.59,7.59H25c0.55,0,1,0.45,1,1c0,0.55-0.45,1-1,1H9.41L17,24.62  c0.34,0.4,0.32,0.99-0.05,1.36c-0.37,0.37-0.96,0.39-1.36,0.05l-9.3-9.29C6.1,16.55,6,16.29,6,16.03c0-0.27,0.1-0.52,0.29-0.71  l9.3-9.29C15.98,5.64,16.61,5.64,17,6.03z" />
    </svg>
  );
};

export default IconArrowLeft;
