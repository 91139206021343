import { forwardRef } from 'react';
import { Icon, Name as IconName } from '../../atoms/Icon/Icon';
import { classnames } from 'tailwindcss-classnames';
import {
  backgroundColor,
  borderColor,
  fill,
  textColor,
  padding,
  borderWidth,
  backgroundOpacity,
  borderOpacity,
  display,
  alignItems,
  flexDirection,
  justifyContent,
  fontSize,
  width,
  transitionDuration,
  transitionTimingFunction,
} from '../../../tailwindcss-types';

export type Size = 'normal' | 'large';

type Props = {
  text: string;
  dark?: boolean;
  isSwitcher?: boolean;
  icon?: {
    name: IconName;
    align: 'left' | 'right';
    small?: boolean;
  };
  large?: boolean;
  flexWidth?: boolean;
  onClick?: () => void;
};

export const Button = forwardRef<HTMLButtonElement, Props>(function Button(
  {
    text,
    dark,
    isSwitcher,
    icon,
    large = false,
    flexWidth = false,
    onClick,
  }: Props,
  ref,
) {
  const buttonClasses = classnames(
    backgroundColor({
      'bg-light': dark && !isSwitcher,
      'bg-dark': !dark && !isSwitcher,
      'hover:bg-dark': dark,
      'hover:bg-light': !dark,
      'bg-transparent': isSwitcher,
    }),
    backgroundOpacity('bg-opacity-50', 'hover:bg-opacity-100'),
    borderColor({
      'border-dark': dark,
      'border-light': !dark,
      'hover:border-light': dark,
      'hover:border-dark': !dark,
    }),
    borderOpacity({
      'border-opacity-100': !isSwitcher,
      'border-opacity-25': isSwitcher,
      'hover:border-opacity-25': !isSwitcher,
      'hover:border-opacity-50': isSwitcher,
    }),
    borderWidth('border-2'),
    fill({
      'fill-dark': dark,
      'fill-light': !dark,
      'hover:fill-light': dark,
      'hover:fill-dark': !dark,
    }),
    textColor({
      'text-dark': dark,
      'text-light': !dark,
      'hover:text-light': dark,
      'hover:text-dark': !dark,
    }),
    padding('px-4', {
      'sm:px-3': !large,
      'sm:py-3b': !large,
      'sm:px-5': large,
      'sm:py-4': large,
      'py-2.5': large,
      'py-1': !large,
      'sm:py-3.5': large,
    }),
    display('flex'),
    flexDirection({
      'flex-row-reverse': icon?.align === 'right',
      'flex-row': icon?.align === 'left',
    }),
    alignItems('items-center'),
    justifyContent('justify-center'),
    width({
      'w-3/4': flexWidth,
    }),
    transitionDuration('duration-300'),
    transitionTimingFunction('ease-in-out'),
  );

  const textClasses = classnames(
    padding({
      'pl-1': icon?.align === 'left',
      'pr-1': icon?.align === 'right',
    }),
    fontSize({ 'text-small': isSwitcher, 'text-normal': !isSwitcher }),
  );

  return (
    <button ref={ref} className={buttonClasses} onClick={onClick}>
      {icon && <Icon name={icon.name} small={icon.small} />}
      <p className={textClasses}>{text}</p>
    </button>
  );
});
