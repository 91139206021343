import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMediaAssetsLangCode } from '../../../utils/language';
import { ActionType, SoundButtonType } from '../../../utils/types';
import { Heading } from '../../atoms/Heading/Heading';
import { Icon } from '../../atoms/Icon/Icon';
import { SoundButton } from '../../cells/SoundButton/SoundButton';
import {
  ImageBackground,
  ImageBackgroundProps,
} from '../../molecules/ImageBackground/ImageBackground';
import { Header } from '../../organisms/Header/Header';
import { SoundDemoPlayer } from './SoundDemoPlayer';

export type ScenarioButton = {
  text: string;
  type: SoundButtonType;
  image: ImageBackgroundProps;
};

type Props = {
  scenarioButtons: ScenarioButton[];
  setNavigatedLink: (type: ActionType) => void;
};

export const SoundDemo: FC<Props> = ({ scenarioButtons, setNavigatedLink }) => {
  const { t } = useTranslation();
  const [showSoundPlayer, setShowSoundPlayer] = useState(false);
  const [selectedSound, setSelectedSound] = useState<SoundButtonType | null>(
    null,
  );
  const [audioType, setAudioType] = useState(true);

  const handleSelectedSound = useCallback((sound: SoundButtonType) => {
    setShowSoundPlayer(true);
    setSelectedSound(sound);
  }, []);

  const handleBackgroundImage = useCallback(
    (type: SoundButtonType | null) => {
      const imageData = scenarioButtons.find((button) => button.type === type);

      if (type === null || imageData === undefined) {
        return {
          src: '/assets/images/sound-demo-bg.jpg',
          position: 'center',
          blur: false,
        };
      }
      return imageData.image;
    },
    [scenarioButtons],
  );

  const handleSoundPlayer = useCallback(() => {
    if (selectedSound) {
      return (
        <SoundDemoPlayer
          selectedSound={selectedSound}
          isAudioWidex={(bool) => setAudioType(bool)}
        />
      );
    }
  }, [selectedSound]);

  return (
    <div className="flex flex-col h-full min-h-[800px] bg-darkGrey">
      <Header showBackButton={true} setNavigatedLink={setNavigatedLink} />

      <div className="relative flex grow z-0">
        <div className="z-10 flex flex-col items-center justify-between w-full py-4 xs:pb-6 xs:pt-6">
          {showSoundPlayer ? (
            <div className="flex flex-col items-center justify-end w-full h-full">
              <Heading
                level="h2"
                color="medium-grey"
                align="center"
                weight="semibold"
              >
                {t('compareAudio')}
              </Heading>

              {handleSoundPlayer()}
            </div>
          ) : (
            <div className="flex flex-col justify-around w-full h-full gap-2 xs:justify-between xs:gap-0 item-center">
              <div className="flex justify-center">
                <div className="w-full max-w-lg sm:w-1/2 lg:mx-w-2xl">
                  <video
                    width="100%"
                    controls
                    controlsList="nodownload"
                    poster="/assets/images/soundemo-video-poster.jpg"
                    onCanPlay={(event) => {
                      // @ts-ignore: Volume does in fact exist on EventTarget
                      event.target.volume = 0.4;
                    }}
                  >
                    <source
                      src={`/assets/videos/${getMediaAssetsLangCode()}/WIDEX-Brand-Film-2021.mp4`}
                      type="video/mp4"
                    />
                    <p>Sorry, your browser doesn't support embedded videos.</p>
                  </video>
                </div>
              </div>

              <Heading
                level="h2"
                color="medium-grey"
                align="center"
                weight="semibold"
              >
                {t('playScenario')}
              </Heading>
            </div>
          )}

          <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full max-w-1024 grid items-start justify-start grid-cols-1 gap-4 px-4 py-3 sm:grid-cols-2 sm:items-center sm:justify-center md:grid-cols-2 xs:pt-5.5 sm:pb-5.5 sm:gap-y-4">
              {scenarioButtons.map((scenario, index) => (
                <SoundButton
                  key={index}
                  text={t(scenario.text)}
                  type={scenario.type}
                  backgroundImage={scenario.image}
                  isSelectedSound={selectedSound === scenario.type}
                  setSelectedSound={(sound) => handleSelectedSound(sound)}
                  audioType={audioType}
                />
              ))}
            </div>

            <div className="px-4 flex flex-col sm:flex-row items-center justify-center fill-lightGrey gap-x-0.5">
              <Icon name="IconHeadphone" />
              <Heading
                level="h5"
                color="medium-grey"
                align="center"
                weight="normal"
              >
                {t('headphonesOnPrompt')}
              </Heading>
            </div>
            <span className="block font-notosans text-xs mt-1 text-mediumGrey">
              {t('recordingsBy')}
            </span>
          </div>
        </div>

        <ImageBackground
          src={handleBackgroundImage(selectedSound).src}
          blur={handleBackgroundImage(selectedSound).blur}
        />
      </div>
    </div>
  );
};
