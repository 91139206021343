import React from 'react';
import { IconSVGProps } from '../Icon';

const IconPlay = ({ small }: IconSVGProps) => {
  return (
    <svg
      width={small ? '24' : '32'}
      height={small ? '24' : '32'}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeWidth={small ? '0.5' : '0'}
        d="M11.59,7.2l11.19,8.21c0.55,0.4,0.55,1.21,0,1.61l-11.19,8.21C10.93,25.71,10,25.24,10,24.42V8.01  C10,7.19,10.93,6.71,11.59,7.2z M8,8.01v16.41c0,2.46,2.79,3.87,4.77,2.42l11.19-8.21c1.63-1.2,1.63-3.64,0-4.84L12.77,5.59  C10.79,4.13,8,5.55,8,8.01z"
      />
    </svg>
  );
};

export default IconPlay;
