import { FC } from 'react';

type Props = {
  imageSrc: string;
};

export const ImageBlock: FC<Props> = ({
  imageSrc,
  children,
}) => (
  <div
    className="h-full flex flex-row items-end bg-cover bg-no-repeat bg-[center_top_-140px] xs:bg-center"
    style={{
      backgroundImage: `url(${imageSrc})`,
    }}
  >
    <div
      className="relative flex flex-row items-end justify-center w-full"
    >
      <span className="flex w-full">
        {children}
      </span>

      <div className="absolute z-0 w-full h-full bg-gradient-to-t from-black/70"></div>
    </div>
  </div>
);
