import React, { FC, useMemo } from 'react';
import { icons } from './icons';

export type IconSVGProps = {
  fill?: string;
  bg?: string;
  small?: boolean;
};

export type Name = keyof typeof icons;

type Props = {
  /**
   * Name of the icon
   */
  name: Name;
  /**
   * Optional flag for smaller icon
   */
  small?: boolean;
};

export const Icon: FC<Props> = ({ name, small }) => {
  const IconByName = useMemo(() => icons[name], [name]);

  if (typeof IconByName === 'undefined') return null;

  return (
    <div className="w-auto max-h-full">
      <IconByName small={small} />
    </div>
  );
};
