import { Dispatch, SetStateAction } from 'react';
import {
  CACHING_MESSAGE,
  CachingStateType,
  TOTAL_NUMBER_OF_CACHED_ITEMS,
  VIDEO_CACHE_IDENTIFIER,
} from './CachingInformation';
import { getLanguage } from '../../../utils/language';

/**
 * Checks if all necessary data (build-bundle, unlocalized-content, localized-content from current language) is cached
 * @returns {boolean} if all items are cached
 */
export const isCachingFinishedOfCurrentLanguage = async (): Promise<boolean> => {
  if (await caches.has(VIDEO_CACHE_IDENTIFIER)) {
    //Get cache storage and count cached items
    const cacheKeysAll = await caches.keys();
    const cachedItemsAll = await Promise.all(
      cacheKeysAll.flatMap((cacheName) =>
        caches
          .open(cacheName)
          .then(async (cache) => (await cache.keys()).length),
      ),
    );
    const cachedItemsCountAll = cachedItemsAll.reduce(
      (itemCount, total) => total + itemCount,
      0,
    );

    const cacheKeysLocalizedItems = await (
      await caches.open(VIDEO_CACHE_IDENTIFIER)
    ).keys();

    return (
      cachedItemsCountAll >= TOTAL_NUMBER_OF_CACHED_ITEMS &&
      cacheKeysLocalizedItems.length > 0 &&
      cacheKeysLocalizedItems[0].url.includes(
        `/assets/videos/${getLanguage().code}/`,
      )
    );
  }

  return false;
};

/**
 * Handles service worker state changes via messages
 * @param event triggered event from serviceWorker.postMessage(...)
 * @param setCachingStatus sets caching status for displaying
 */
export const handleServiceWorkerState = async (
  event: MessageEvent<any>,
  setCachingStatus: Dispatch<SetStateAction<CachingStateType>>,
): Promise<void> => {


    //Set CachingStatus based on service worker message (= state)
    if (typeof event.data === 'string') {
      switch (event.data) {
        case CACHING_MESSAGE.reset:
          setCachingStatus({
            start: false,
            bundleFiles: false,
            unlocalizedContent: false,
            localizedContent: false,
            failedFetching: false,
          });
          break;
        case CACHING_MESSAGE.start:
          setCachingStatus((prev) => ({ ...prev, start: true }));
          break;
        case CACHING_MESSAGE.bundleFiles:
          setCachingStatus((prev) => ({ ...prev, bundleFiles: true }));
          break;
        case CACHING_MESSAGE.unlocalizedContent:
          setCachingStatus((prev) => ({ ...prev, unlocalizedContent: true }));
          break;
        case CACHING_MESSAGE.localizedContent:
          setCachingStatus((prev) => ({ ...prev, localizedContent: true }));
          break;
      }
    }
};
