import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { Heading } from '../../atoms/Heading/Heading';
import { Icon } from '../../atoms/Icon/Icon';

type Props = {
  audio: string;
  handleAudioProgress: (currentTime: number) => void;
  toggledTime: number;
  scenarioChange: boolean;
  setScenarioChange: (scenarioChange: boolean) => void;
};

export const SoundWaveform: FC<Props> = ({
  audio,
  handleAudioProgress,
  toggledTime,
  scenarioChange,
  setScenarioChange,
}) => {
  const [waveform, setWaveform] = useState<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState(toggledTime);
  const [durationString, setDurationString] = useState('0:00');
  const [currentTimeString, setCurrentTimeString] = useState('0:00');
  const waveContainer = useRef<HTMLDivElement>(null);

  const handlePause = useCallback(() => {
    if (waveform) {
      waveform.playPause();
      setIsPlaying(waveform.isPlaying());
    }
  }, [waveform]);

  const handleSecondsToTimeFormat = useCallback((inputSeconds: number) => {
    inputSeconds = Math.floor(inputSeconds);
    const hours = Math.floor(inputSeconds / 3600);
    const minutes = Math.floor((inputSeconds - hours * 3600) / 60);
    const seconds = inputSeconds - hours * 3600 - minutes * 60;

    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }, []);

  useEffect(() => {
    if (waveContainer.current) {
      const initialWaveform = WaveSurfer.create({
        container: waveContainer.current,
        barWidth: 5,
        barRadius: 5,
        barGap: 10,
        barHeight: 7,
        barMinHeight: 5,
        cursorWidth: 1,
        backend: 'MediaElement',
        height: 50,
        responsive: true,
        waveColor: 'rgba(210,205,200,0.6)',
        progressColor: '#FFFFFF',
        cursorColor: 'transparent',
        normalize: true,
      });

      setWaveform(initialWaveform);

      initialWaveform.load(audio);

      initialWaveform.on('ready', function () {
        if (!scenarioChange) {
          // keep playback positon if widex/competitor toggle is toggled
          if (isPlaying) {
            initialWaveform.play(toggledTime);
          } else {
            initialWaveform.skipForward(toggledTime);
          }
        } else {
          // reset player on scenario change
          setCurrentTimeString('0:00');
          setCurrentTime(0);
          setIsPlaying(false);
          initialWaveform.stop();
          setScenarioChange(false);
        }

        setDurationString(
          handleSecondsToTimeFormat(initialWaveform.getDuration()),
        );
      });

      initialWaveform.on('audioprocess', function () {
        setCurrentTime(initialWaveform.getCurrentTime());

        setCurrentTimeString(
          handleSecondsToTimeFormat(initialWaveform.getCurrentTime()),
        );
      });

      initialWaveform.on('finish', function () {
        initialWaveform.play(0);
      });

      return () => {
        initialWaveform.destroy();
      };
    }
    // Disabling to allow the waveform to be controlled for play and pause
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, handleSecondsToTimeFormat]);

  useEffect(() => {
    //propagate current playback time upwards, only if there is playback
    if (currentTime !== 0) {
      handleAudioProgress(currentTime);
    }
  }, [currentTime, handleAudioProgress]);

  return (
    <div className="w-full">
      <div className="flex flex-col-reverse xs:flex-row items-center justify-center gap-3.5">
        <div className="flex items-center">
          <button
            className="duration-300 ease-in-out fill-darkGrey bg-light hover:bg-mediumGrey w-5.5 h-5.5 rounded-full flex items-center justify-center"
            onClick={() => handlePause()}
            type="button"
          >
            {isPlaying ? <Icon name="IconPause" /> : <Icon name="IconPlay" />}
          </button>
        </div>
        <div className="w-full duration-300 ease-in-out" ref={waveContainer} />
      </div>
      <div className="flex flex-row items-center justify-between px-4 pt-1 pb-3 xs:pb-0 xs:pl-6 xs:pr-0 -mt-5.5 xs:mt-0">
        <Heading level="h4" color="medium-grey">
          {currentTimeString}
        </Heading>
        <Heading level="h4" color="medium-grey">
          {durationString}
        </Heading>
      </div>
    </div>
  );
};
