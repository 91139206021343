import i18n from 'i18next';

const LANGUAGE_CODE = {
  en: 'en',
  it: 'it',
  nb: 'nb',
  de: 'de',
  // fr: 'fr',
  // ar: 'ar',
} as const;

export type LANGUAGE_CODE_TYPE = keyof typeof LANGUAGE_CODE;
export type LANGUAGE_CODE_LABEL_TYPE = {
  code: LANGUAGE_CODE_TYPE;
  label: string;
};

type LANGUAGES_TYPE = Record<LANGUAGE_CODE_TYPE, LANGUAGE_CODE_LABEL_TYPE>;

export const LANGUAGES: LANGUAGES_TYPE = {
  en: { code: 'en', label: 'English' },
  it: { code: 'it', label: 'Italiano' },
  nb: { code: 'nb', label: 'Norsk' },
  de: { code: 'de', label: 'Deutsch' },
  // fr: { code: 'fr', label: 'Français' },
  // ar: { code: 'ar', label: 'Arabic' },
} as const;

export const FALLBACK_LANGUAGE_CODE: LANGUAGE_CODE_TYPE = LANGUAGE_CODE.en;
export const LOCALSTORAGE_CONFIG_KEY = 'sounddemo-widex-config';

/**
 * Gets language from local storage
 * @returns langCode | null (depending on whether a language has already been saved)
 */
const getLanguageFromLocalStorage = (): LANGUAGE_CODE_TYPE | null => {
  try {
    const langCode = localStorage.getItem(LOCALSTORAGE_CONFIG_KEY);

    if (typeof langCode === 'string' && JSON.parse(langCode).langCode) {
      return JSON.parse(langCode).langCode;
    }
  } catch (e) {
    console.warn(
      'LocalStorage is not available. Language cannot be read from LocalStorage',
    );
  }
  return null;
};

/**
 * Gets current language
 * @returns langCode (stored in local storage or fallback langCode)
 */
export const getLanguage = (): LANGUAGE_CODE_LABEL_TYPE => {
  return LANGUAGES[getLanguageFromLocalStorage() || FALLBACK_LANGUAGE_CODE];
};

/**
 * Gets the {langCode} for media assets.
 * If a language doesn't have own media assets, it will return 'en' and therefore loads the english media assets
 * @returns {langCode} for media assets
 */
export const getMediaAssetsLangCode = (
  language?: LANGUAGE_CODE_TYPE,
): LANGUAGE_CODE_TYPE => {
  const langCode = language ?? getLanguage().code;
  switch (langCode) {
    case LANGUAGE_CODE.it:
    case LANGUAGE_CODE.de:
      return langCode;
    default:
      return LANGUAGE_CODE.en;
  }
};

/**
 * Switch language, change html document language-tag, store langCode to local storage
 * @param langCode (language code)
 */
export const switchLanguage = (langCode: LANGUAGE_CODE_TYPE) => {
  i18n.changeLanguage(langCode);
  document.documentElement.lang = langCode;

  //Send service worker message with new language
  navigator.serviceWorker
    .getRegistration()
    .then((registration) => {
      registration?.active?.postMessage(getLanguage().code);
      registration?.update();
    })
    .catch(console.warn);

  try {
    localStorage.setItem(
      LOCALSTORAGE_CONFIG_KEY,
      JSON.stringify({ langCode: langCode }),
    );
  } catch (e) {
    console.warn(
      'LocalStorage is not available. Choosen language cannot be stored as new default language.',
    );
  }
};

// returns true if layout should be right to left (arabic script).
// export const isRight2leftLayout = (): boolean => {
//   return getLanguage() === LANGUAGE_CODE.ar;
// };
